.navbar-dark {
  background-color: rgb(23,54,92) !important;
  color: white;
  border-bottom: 5px solid $red;
  .nav-item {
    font-weight: 200;
    font-size: 13px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }
  .navbar-nav .nav-link {
    color: white;
  }
}
.logo {
  max-width: 175px;
  width: 100%;
}
.row {
  margin: 0;
}
#banner {
  .carousel-caption {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 10px;
    h2 {
      font-size: 1.275rem;
      letter-spacing: .17rem;
      font-weight: 200;
    }
    h3 {
      font-size: 1.1rem;
    }
    a {
      font-size: .8rem;
      color: white;
    }
  }
  &:after {
    content: " ";
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(0,86,145, .8);
    top: 0;
  }
  .arrow-down {
    transform: rotate(90deg);
    color: white;
    font-size: 3rem;
    z-index: 11;
    position: absolute;
    bottom: 30px;
    left: 50%;
  }
}
.white-container, .video-section {
  padding: 25px 0;
  h2 {
    text-transform: uppercase;
    text-align: center;
    color: $blue;
  }
  .border-left {
    border-left: 1px solid $blue !important;
  }
}

.three-cards {
  display: flex;
  justify-content: center;
  flex-direction: column;
  .link-card {
    position: relative;
    text-align: center;
    width: 75%;
    padding: 25px 25px 35px;
    margin: 20px auto;
    background-color: rgb(240,239,237);
    text-decoration: none;
    transition: .5s;
    &:hover {
      background-color: $red;
      h4, .more {
        color: white;
      }
    }
    img {
      width: 40%;
    }
    h4 {
      color: $red;
      padding: 20px 0;
    }
    .more {
      position: absolute;
      bottom: 25px;
      transform: translate(-50%, 0);
    }
  }
}
.video-section {
  p {
    color: white;
    margin-top: 15px;
  }
}
.visit-us {
  a {
    margin: 5px;
    img {
      max-width: 30px;
      opacity: .95;
      &:hover {
        opacity: 1;
      }
    }
  }
}
.btn-red {
  color: #fff;
  background-color: $red;
  border-color: $red;
  border-radius: 0;
  font-size: .85rem;
  opacity: .95;
  &:hover {
    opacity: 1;
    color: white;
  }
}
.red-text {
  color: $red;
}
.paroller {
  background-image: linear-gradient(rgba(2,2,2,.55), rgba(2,2,2,.55)), url("../img/goldman-pic.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.image-and-form {
  .row {
    margin: 0 auto;
    flex-direction: column;
    .rely-on {
      background: linear-gradient(rgba(2,2,2,.55), rgba(2,2,2,.55)), url("../img/business-image.jpg") center center no-repeat;
      background-size: 135%;
      text-align: center;
      img {
        width: 75%;
        margin: 20% auto;
      }
    }
  }
  .form-section {
    background-color: $blue;
    color: white;
    .contain {
      max-width: 600px;
      margin: 0 auto;
    }
    h4 {
      color: white;
      font-size: 1.8rem;
    }
  }
}
.our-locations {
  color: $blue;
  text-align: center;
  h2 {
    margin-bottom: 20px;
  }
  .d-flex {
    justify-content: space-around;
    flex-direction: column;
  }
  span {
    margin: 10px;
    img {
      height: 30px;
      margin-right: 7px;
    }
  }
}
form {
  font-weight: 300;
}
footer {
  padding: 25px 0;
  background-color: $blue;
  font-weight: 300;
  .footer-container {
    color: white;
    font-size: 14px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    a {
      color: white;
      align-self: center;
    }
    .footer-logo {
      margin-bottom: 25px;
      img {
        width: 320px;
      }
    }
  }
  .footer-icons {
    a {
      display: inline-block;
      margin: 12.5px;
      img {
        width: 28px;
      }
    }
  }
}

.next-move-icon {
  max-width: 85px;
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}
#banner.interior-banner {
  .carousel-caption {
    h2 {
      font-size: 1.7rem;
    }
  }
}

.employee {
  text-align: center;
  text-decoration: none !important;
  position: relative;
  transition: .2s;
  margin: 35px 20px;
  &:hover {
    .view-bio {
      opacity: 100;
    }
    .red-cover {
      background-color: rgba(210,33,36,.7);
    }
    .employee-info {
      color: white;
      top: 50%;
      transform: translate(0, -50%);
    }
    .employee-contact {
      opacity: 100;
    }
  }
  .employee-info {
    position: absolute;
    top: 100%;
    width: 100%;
    transition: .2s;
    margin-top: 5px;
    font-size: .85rem;
    .employee-name {
      text-transform: uppercase;
      font-weight: 600;
    }
    .employee-title {
      font-weight: 300;
    }
  }
  .employee-contact {
    position: absolute;
    top: 100%;
    width: 100%;
    font-size: .75rem;
    margin-top: 8px;
    opacity: 0;
    transition: .2s;
  }
  .employee-image {
    position: relative;
    max-width: 185px;
  }
  .red-cover {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(210,33,36,0);
    transition: .2s;
  }
  .view-bio {
    position: absolute;
    opacity: 0;
    margin-top: 50px;
    color: $red;
    width: 100%;
    border-top: rgba(210, 33, 36, 1) 2px solid;
    transition: .2s;
    font-size: .75rem;
  }
}
.grey-section, .white-section-interior, .blue-section {
  padding: 30px 0;
  h2 {
    color: $red;
    text-transform: uppercase;
  }
}
.blue-section {
  background-color: $blue;
  color: white;
}
.grey-section {
  background-color: rgb(240, 239, 238);
  .right-section {
    margin-top: 30px;
  }
}
.blue-bar {
  background: url("../img/chad-select-usa.png") no-repeat left top, linear-gradient(to right, rgb(63,116,164) 0%, rgb(63,116,164) 10%), linear-gradient(to right, rgb(63,116,164) 0%, rgb(63,116,164) 10%);
  background-size: 105%;
  background-blend-mode: soft-light;
  color: white;
  .col-md-6 {
    padding: 35px 10px;
    .text-large {
      max-width: 600px;
    }
  }
}
.text-large {
  font-size: 2rem;
  font-weight: 200;
}
.white-section-interior {
  .three-gears {
    width: 100%;
    max-width: 300px;
  }
}
.custom-counter {
  padding: 0;
  list-style-type: none;
  margin-top: 25px;
  margin-bottom: 0;
}

.custom-counter li {
  counter-increment: step-counter;
  font-size: 1rem;
  position: relative;
  margin-left: 40px;
  min-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px 6px;
}

.custom-counter li::before {
  content: counter(step-counter);
  margin-right: 10px;
  background-color: #15304e;
  color: white;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: inline-block;
  text-align: center;
  line-height: 40px;
  font-size: 1.75rem;
  font-weight: 300;
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translate(0, -50%);
}
.partners {
  text-align: center;
  .d-flex {
    a {
      width: 20%;
      margin: 2.5%;
      display: inline-table;
    }
  }
}