@media (min-width: 992px) {
  .navbar {
    padding: 1.75rem 1.4rem;
  }
  .navbar-dark {
    border-bottom: 8px solid #d22124;
    .nav-item {
      font-size: 14.5px;
    }
    .logo {
      max-width: 320px;
    }
  }
  #banner .carousel-caption {
    h2 {
      font-size: 2.75rem;
    }
    h3 {
      font-size: 1.75rem;
    }
  }
  .white-container .visit-us h2 {
    margin-bottom: .75rem;
  }
  .white-container .border-left {
    padding: 18px;
  }
  .white-container.our-locations {
    padding: 70px;
    h2 {
      margin-bottom: 35px;
    }
  }
  footer {
    padding: 50px 0;
    .footer-icons {
      margin-top: 10px;
    }
  }
  .blue-bar {
    .col-md-6 {
      padding: 50px 10px;
    }
  }
  .custom-counter {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px !important;
  }
  .three-cards {
    padding: 0 60px;
    .link-card {
      padding: 35px 35px 45px;
    }
  }
}

@media (min-width: 1200px) {
  #banner .carousel-caption {
    h2 {
      font-size: 3rem;
    }
    h3 {
      font-size: 2rem;
    }
  }
  .video-section {
    .row {
      justify-content: space-around;
      .col-md-6 {
        max-width: 40%;
      }
    }
  }
  .blue-bar {
    .col-md-6 {
      padding: 90px 10px;
    }
  }
  .text-large {
    font-size: 2.5rem;
  }
  #banner.interior-banner {
    .carousel-caption {
      h2 {
        font-size: 3rem;
      }
    }
  }
  .three-cards .link-card {
    margin: 1% 4.5%;
  }
  .image-and-form .form-section h4 {
    font-size: 2.63rem;
  }
  .our-locations {
    font-size: 1rem;
  }
  .image-and-form .row .rely-on {
    background-size: 150%;
  }
}