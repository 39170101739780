@media (min-width: 768px) {
  .navbar {
    padding: 1.25rem 1rem;
  }
  .navbar-nav {
    width: 100%;
    justify-content: space-around;
    .the-spacer {
      display: flex;
      justify-content: space-around;
      flex: 1;
    }
    .logo-spacer {
      flex: 1;
      text-align: center;
    }
  }
  #banner .carousel-caption {
    h2 {
      font-size: 2.3rem;
    }
    h3 {
      font-size: 1.5rem;
      letter-spacing: .125rem;
    }
    a {
      display: inline-block;
      margin-top: 70px;
      letter-spacing: .12rem;
      font-size: 1rem;
    }
  }
  .three-cards {
    flex-direction: row;
    .link-card {
      padding: 35px 25px 45px;
      margin: 20px;
    }
  }
  .white-container h2 {
    letter-spacing: .115rem;
  }
  .white-container, .video-section {
    padding: 55px 0;
  }
  .visit-us {
    a {
      margin: 8px;
      img {
        max-width: 38px;
      }
    }
  }
  .image-and-form {
    .row {
      flex-direction: row;
      .rely-on {
        align-items: center;
      }
    }
    .form-section {
      display: flex;
      h4 {
        font-size: 2rem;
      }
      .contain {
        align-self: center;
      }
    }
  }
  .our-services h2 {
    margin-bottom: 1.2rem;
  }
  .logo {
    max-width: 250px;
  }
  .our-locations {
    font-size: .72rem;
    .d-flex {
      flex-direction: row;
    }
  }
  #banner.interior-banner {
    .carousel-caption {
      h2 {
        font-size: 2.3rem;
      }
    }
  }
  .blue-bar {
    background-size: 50%;
  }
  .white-section-interior, .grey-section, .blue-section {
    padding: 50px 0;
  }
  .image-and-form .row .rely-on {
    background-size: 200%;
  }
}